<script>
import MenuForm from "@/views/menu/menu/menuForm";
import Component, { mixins } from "vue-class-component";

@Component({
  inject: {
    menuService: "menuService",
    visitorLevelService: "visitorLevelService"
  }
})
export default class MenuDetail extends mixins(MenuForm) {
  formId = "detail-menu-form";

  get decoratedElements() {
    return this.allReadonlyFields;
  }

  get fieldColumns() {
    return 2;
  }

  getFormTitle () {
    return this.translations.pageTitles.menus_detail;
  }

  async afterCreate () {
    this.model = await this.menuService.read(this.$route.params.id);
    this.setVisitorLevels(await this.visitorLevelService.enabledLevels(this.model.id));
    this.model.visitorLevels = this.currentVisitorLevelsValue();

    this.isReady = true;
  }
}
</script>